import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
    type Container,
    type ISourceOptions,
    type Engine
} from "@tsparticles/engine";

import { loadSlim } from "@tsparticles/slim";

const ParticlesApp = () => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine: Engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = async (container?: Container): Promise<void> => { };

    const options: ISourceOptions = useMemo(
        () => ({
            particles: {
                number: {
                    value: 250
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "out"
                    },
                    random: true,
                    speed: .2,
                    straight: false
                },
                opacity: {
                    animation: {
                        enable: true,
                        speed: 1,
                        sync: false
                    },
                    value: {
                        min: 0,
                        max: 1
                    }
                },
                size: {
                    value: {
                        min: .5,
                        max: 2
                    }
                }
            },
            detectRetina: true,
        }),
        [],
    );

    if (init) {
        return (
            <Particles
                id="tsparticles"
                particlesLoaded={particlesLoaded}
                options={options}
            />
        );
    }

    return <></>;
};

export default ParticlesApp;