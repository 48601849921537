import React, { useEffect, useRef } from 'react';

const Galaxy: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const rint = 70;
    let WIDTH: number, HEIGHT: number, con: CanvasRenderingContext2D, g: CanvasGradient;
    const pxs: Circle[] = [];

    useEffect(() => {
        const windowSize = () => {
            if (canvasRef.current) {
                WIDTH = canvasRef.current.clientWidth;
                HEIGHT = canvasRef.current.clientHeight;
                canvasRef.current.width = WIDTH;
                canvasRef.current.height = HEIGHT;
            }
        };

        windowSize();
        window.addEventListener('resize', windowSize);

        if (canvasRef.current) {
            con = canvasRef.current.getContext('2d') as CanvasRenderingContext2D;
            for (let i = 0; i < 100; i++) {
                pxs[i] = new Circle();
                pxs[i].reset();
            }

            const draw = () => {
                con.clearRect(0, 0, WIDTH, HEIGHT);
                con.globalCompositeOperation = 'lighter';
                for (let i = 0; i < pxs.length; i++) {
                    pxs[i].fade();
                    pxs[i].move();
                    pxs[i].draw();
                }
                requestAnimationFrame(draw);
            };

            requestAnimationFrame(draw);
        }

        return () => {
            window.removeEventListener('resize', windowSize);
        };
    }, []);

    class Circle {
        s = {
            ttl: 15000,
            xmax: 1.5,
            ymax: 1.5,
            rmax: 17,
            rt: 1,
            xdef: 960,
            ydef: 540,
            xdrift: 2,
            ydrift: 2,
            random: true,
            blink: true,
        };
        x: number = 0;
        y: number = 0;
        r: number = 0;
        dx: number = 0;
        dy: number = 0;
        hl: number = 0;
        rt: number = 0;
        stop: number = 0;

        reset() {
            this.x = this.s.random ? WIDTH * Math.random() : this.s.xdef;
            this.y = this.s.random ? HEIGHT * Math.random() : this.s.ydef;
            this.r = (this.s.rmax - 1) * Math.random() + 1;
            this.dx = Math.random() * this.s.xmax * (Math.random() < 0.5 ? -1 : 1);
            this.dy = Math.random() * this.s.ymax * (Math.random() < 0.5 ? -1 : 1);
            this.hl = (this.s.ttl / rint) * (this.r / this.s.rmax);
            this.rt = Math.random() * this.hl;
            this.s.rt = Math.random() + 1;
            this.stop = Math.random() * 0.2 + 0.4;
            this.s.xdrift *= Math.random() * (Math.random() < 0.5 ? -1 : 1);
            this.s.ydrift *= Math.random() * (Math.random() < 0.5 ? -1 : 1);
        }

        fade() {
            this.rt += this.s.rt;
        }

        draw() {
            if (this.s.blink && (this.rt <= 0 || this.rt >= this.hl)) this.s.rt *= -1;
            else if (this.rt >= this.hl) this.reset();
            const newo = 1 - this.rt / this.hl;
            con.beginPath();
            con.arc(this.x, this.y, this.r, 0, Math.PI * 2, true);
            con.closePath();
            const cr = this.r * newo;
            g = con.createRadialGradient(this.x, this.y, 0, this.x, this.y, cr <= 0 ? 1 : cr);
            g.addColorStop(0.0, `rgba(193,254,254,${newo})`);
            g.addColorStop(this.stop, `rgba(193,254,254,${newo * 0.2})`);
            g.addColorStop(1.0, 'rgba(193,254,254,0)');
            con.fillStyle = g;
            con.fill();
        }

        move() {
            this.x += (this.rt / this.hl) * this.dx;
            this.y += (this.rt / this.hl) * this.dy;
            if (this.x > WIDTH || this.x < 0) this.dx *= -1;
            if (this.y > HEIGHT || this.y < 0) this.dy *= -1;
        }
    }

    return (
        <div className="galaxy-wrapper" style={{ width: '100%', height: '100%' }}>
            <canvas ref={canvasRef} id="galaxy" style={{ width: '100%', height: '100%' }}></canvas>
        </div>
    );
};

export default Galaxy;
