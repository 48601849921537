import { HeadFC } from 'gatsby'
import React, { useEffect, useState } from 'react'
import SEO from '../components/SEO'
import MobileLayout from '../components/index/mobile/MainLayout'
import ComputerLayout from '../components/index/computer/MainLayout'
import RotateLayout from '../components/index/mobile/RotateLayout'

const getWidth = () =>
  typeof window !== 'undefined'
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : 0
const getHeight = () =>
  typeof window !== 'undefined'
    ? window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    : 0

export const HomePage = () => {
  let [width, setWidth] = useState(getWidth())
  let [height, setHeight] = useState(getHeight())

  useEffect(() => {
    if (typeof window === 'undefined') return
    const resizeListener = () => {
      setWidth(getWidth())
      setHeight(getHeight())
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width > 0 && height > 0 ? (
    width < 1000 ? (
      height - width > 0 ? (
        <MobileLayout />
      ) : (
        <RotateLayout />
      )
    ) : (
      <ComputerLayout />
    )
  ) : (
    <></>
  )
}

export default HomePage

export const Head: HeadFC = () => (
  <SEO
    title="Digit Universe - Photographie à Lille et Recrutement Web & IT"
    description="Bienvenue chez Digit Universe, votre partenaire multifacette à Lille offrant des services de photographie professionnelle et de recrutement Web & IT. Découvrez nos séances photo en studio ou en extérieur et comment nous aidons les entreprises à trouver les talents numériques. Explorez nos solutions web, communication, et formations sur mesure."
    robotsContent="index, follow"
  />
)
