import React from 'react'
import { css } from '../../../../styled-system/css'

const Spacer = () => {
    return (
        <div className={css({
            padding: "0.5rem",
        })}>
            <h1 className={css({
                fontFamily: "'Exo 2' !",
                fontSize: "1.5rem",
                textAlign: "center",
                color: "#fff",
                position: "relative",
                w: "100%",
                _before: {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: "50%",
                    left: "-2.25rem",
                    width: "1.5rem",
                    height: ".2rem",
                    backgroundColor: "#fff",
                },
                _after: {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: "50%",
                    right: "-2.25rem",
                    width: "1.5rem",
                    height: ".2rem",
                    backgroundColor: "#fff",
                },
            })}>Choisissez votre univers</h1>
        </div>
    );
}

export default Spacer;