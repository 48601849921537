import React from 'react'
import { getPath } from '../../photographie/Image'
import { css } from '../../../../styled-system/css'
import Menus from './Menus'

// @ts-expect-error
import Logo from "../../../images/logo_r_w.svg"

const MobileLayout = () => {
    return (
        <div
            className={css({
                height: '100vh',
                width: '100%',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
            })}
            style={{
                background: "url('" + getPath('index/bgMob.png') + "')",
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                
            }}>
            <div className={css({
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                w: "100%",
                padding: "1rem",
                gap: "0.25rem"
            })}>
                <img className={css({
                    w: "50%",
                    marginBottom: "0.5rem"
                })} src={Logo} alt="" />
                <h1 className={css({
                    color: "white",
                    fontFamily: "'Exo 2' !",
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    textAlign: "center"
                })}>
                    Bienvenue chez Digit Universe !
                </h1>
                <h2 className={css({
                    color: "white",
                    fontFamily: "'Exo 2' !",
                    fontSize: "0.75rem",
                    fontWeight: "300",
                    textAlign: "center"
                })}>
                    Experts en recrutement IT & Web et photographie à Lille, nous proposons des services personnalisés pour tous vos besoins professionnels et personnels.
                </h2>
            </div>

            <Menus />
        </div>
    );
}

export default MobileLayout;