import React from 'react'
import { css } from '../../../../styled-system/css'

// @ts-expect-error
import Phone from "../../../images/index/Phone.svg"

const RotateLayout = () => {
    return (
        <div
            className={css({
                height: '100vh',
                width: '100%',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                overflow: "hidden",
                background: "#1d1d1b",
                gap: "1rem"
            })}>
            <img className={css({
                h: "50%"
            })} src={Phone} alt="" />
            <h1 className={css({
                color: "white",
                fontFamily: "Assistant !",
                fontSize: "2rem",
                fontWeight: "600",
                textAlign: "center",
            })}>Tourner votre téléphone</h1>
        </div>
    );
}

export default RotateLayout;