import React from 'react'
import { css } from '../../../../styled-system/css'

// @ts-expect-error
import Rocket_B from '../../../images/index/Rocket_B.svg'
// @ts-expect-error
import Rocket_G from '../../../images/index/Rocket_G.svg'
import Spacer from './Spacer'
import Menu from './Menu'

const Menus = () => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Menu
        title="Expertise en recrutement"
        color="#006BFF"
        link="/recrutement/"
        icon={Rocket_B}
      >
        <p>
          Découvrez notre service de recrutement spécialisé dans l&apos;IT et le
          Web.
        </p>
        <p>
          En tant que chasseur de tête, nous excellons dans le sourcing et le
          recrutement de talents d'exception. Notre expertise en recrutement
          vous assure de trouver les professionnels les mieux adaptés à vos
          besoins.
        </p>
        <p>
          Faites confiance à notre équipe pour un recrutement de talent efficace
          et une croissance optimale de votre entreprise.
        </p>
        <p>
          Contactez-nous dès aujourd'hui pour des solutions sur mesure et un
          accompagnement personnalisé.
        </p>
      </Menu>
      <Spacer />
      <Menu
        title="Photographie"
        color="#D9C58B"
        link="/photographie/"
        icon={Rocket_G}
      >
        <p>
          Découvrez notre service de photographe à Lille spécialisé en studio et
          lifestyle.
        </p>
        <p>
          En tant qu'experts en shooting photo, nous excellons dans la capture
          de moments précieux de votre vie. Notre expertise en séances photo
          vous assure des souvenirs authentiques et élégants, que ce soit pour
          une grossesse, une naissance, un baptême, un mariage, ou des portraits
          de famille. Faites confiance à notre équipe pour des séances photo
          uniques et une expérience inoubliable.
        </p>
        <p>
          Contactez-nous dès aujourd'hui pour des solutions sur mesure et un
          accompagnement personnalisé dans notre studio photo à Chéreng près de
          Lille.
        </p>
      </Menu>
    </div>
  )
}

export default Menus
