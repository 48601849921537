import React from 'react'
import { css } from '../../../../styled-system/css'
import { Link } from 'gatsby';

type MenuProps = {
    children: React.ReactNode;
    link: string;
    icon: string;
    title: string;
    color: string;
};

const Menu = ({ children, link, icon, title, color }: MenuProps) => {
    return (
        <div className={css({
            w: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            flexDirection: "column",
            gap: "0.5rem",
            borderTop: ".125rem solid " + color,
            borderBottom: ".125rem solid " + color,
            padding: "0.5rem 1rem",
        })}>
            <h2 className={css({
                fontFamily: "'Exo 2' !",
                fontSize: "1rem",
                fontWeight: "600",
                textAlign: "center",
                textTransform: "uppercase",
                color: color,
                letterSpacing: ".05rem",
            })}>{title}</h2>
            <div className={css({
                fontFamily: "Lato !",
                fontSize: "0.8rem",
                fontWeight: "300",
                color: "#FFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.5rem",
            })}>
                {children}
            </div>
            <Link to={link} className={css({
                marginBottom: ".5rem",
                cursor: "pointer"
            })}>
                <button className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: color,
                    textTransform: "uppercase",
                    fontFamily: "'Exo 2' !",
                    fontSize: "1.25rem",
                    fontWeight: "600",
                    letterSpacing: ".1rem",
                    gap: ".5rem",
                    padding: ".5rem 1rem",
                    border: ".125rem solid " + color,
                    position: "relative",
                    cursor: "pointer",
                })}>
                    J'y vais
                    <img src={icon} alt="" />
                </button>
            </Link>
        </div>
    );
}

export default Menu;